import React from "react";
import {IntlProvider} from "react-intl";
import messages from "../intl/messages";
import {StaticImage} from "gatsby-plugin-image";

const IndexLayout = ({children, lang}) => {
    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            <div className="bg-primary min-h-screen">
                <div className="absolute left-0 right-0 top-0 z-0 overflow-hidden">
                    <StaticImage src="../images/header.png" quality="100" alt="" className="h-full w-screen"/>
                </div>
                <div className="container z-10 relative">
                    {children}
                </div>
            </div>
        </IntlProvider>
    );
}

export default IndexLayout;