import React from "react";
import {Helmet} from "react-helmet";
import {useIntl} from "react-intl";
import {languages} from "../../languages";

const SEO = ({lang, titleKey, descriptionKey, slug, location}) => {
    const intl = useIntl();
    const title = intl.formatMessage({id: titleKey});
    const description = intl.formatMessage({id: descriptionKey});
    console.log(description);
    return <Helmet title={title} description={description}>
        <html lang={lang} />
        <meta name="description" content={description}/>
        {
            languages.map((language) => <link rel="alternate" hrefLang={language} href={`${location.origin}/${language}${slug}`}/>)
        }
    </Helmet>
}

export default SEO;