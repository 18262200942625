import React from "react";
import {FormattedMessage} from "react-intl";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const PrimarySection = () => {
    return (
        <div className="min-h-screen flex flex-col-reverse md:flex-row md:justify-evenly items-center">
            <div className="text-center md:w-1/2 lg:w-1/2 items-center flex flex-col mt-8 md:mt-24">
                <h1 className="text-2xl md:text-4xl text-white font-bold mb-2">
                    <FormattedMessage id="waveBudget"/>
                </h1>
                <h2 className="text-lg md:text-2xl text-white font-bold mb-4">
                    <FormattedMessage id="slogan"/>
                </h2>

                <div className="text-left w-auto mb-8">
                    <p className="text-white font-bold md:text-lg my-4">
                        <span
                            className="bg-white text-primary rounded-full w-6 h-6 md:w-7 md:h-7 inline-block mr-2 text-center">1</span>
                        <FormattedMessage id="primarySectionStep1"/>
                    </p>
                    <p className="text-white font-bold md:text-lg my-4">
                        <span
                            className="bg-white text-primary rounded-full w-6 h-6 md:w-7 md:h-7 inline-block mr-2 text-center">2</span>
                        <FormattedMessage id="primarySectionStep2"/>
                    </p>
                    <p className="text-white font-bold md:text-lg my-4">
                        <span
                            className="bg-white text-primary rounded-full w-6 h-6 md:w-7 md:h-7 inline-block mr-2 text-center">3</span>
                        <FormattedMessage id="primarySectionStep3"/>
                    </p>
                </div>

                <div className="flex flex-row justify-evenly items-stretch mb-12">
                    <Link to="https://apps.apple.com/app/apple-store/id1597718926?pt=120165403&ct=landing_page&mt=8"
                          target="_blank"
                          className="w-1/2 flex items-center justify-center">
                        <StaticImage
                            src="../../images/apple_badge_en.svg"
                            alt="Download on the App Store"
                            quality="90"/>
                    </Link>
                    <Link to="https://play.google.com/store/apps/details?id=at.stefangaller.wave_budget"
                          target="_blank"
                          className="w-1/2">
                        <StaticImage
                            height="70"
                            src="../../images/google-play-badge_en.png"
                            alt="Download from Google Play"
                            quality="90"/>
                    </Link>
                </div>
            </div>
            <div className="w-2/3 md:w-1/4 h-1/2 mt-24 lg:mt-32 md:mt-0 flex items-center justify-center">
                <StaticImage
                    src="../../images/phone-primary.png"
                    alt=""
                    quality="100"
                    placeholder="svgTrace"/>
            </div>
        </div>
    );
}

export default PrimarySection;