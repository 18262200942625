import React from "react";
import HomeLayout from "../layouts";
import HomeContent from "../components/home/HomeContent";
import SEO from "../components/seo";

const HomePage = ({pageContext, location}) => {
    const {lang} = pageContext;

    return (
        <HomeLayout lang={lang}>
            <SEO titleKey={'waveBudget'} descriptionKey={'slogan'} slug='/' lang={lang} location={location}/>
            <HomeContent lang={lang} />
        </HomeLayout>
    );
}

export default HomePage;